import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const blogPosts = {
  'why-the-name-k4m2a': {
    title: "Why the name K4M2A?",
    date: "June 1, 2023",
    content: `
      <p>
        The name K4M2A might seem cryptic at first glance, but it holds a deep significance that reflects our mission and vision. Let's break it down and explore the meaning behind each element.
      </p>
      <h2>The Breakdown</h2>
      <p>
        K4M2A is an alphanumeric representation of the phrase "Karma". Here's how it breaks down:
      </p>
      <ul>
        <li>K = K</li>
        <li>4 = A (4 looks like a reversed A)</li>
        <li>M = M</li>
        <li>2 = R (2 resembles a reversed R)</li>
        <li>A = A</li>
      </ul>
      <h2>Why Karma?</h2>
      <p>
        Karma is a concept found in many Eastern philosophies and religions, particularly in Hinduism and Buddhism. It refers to the spiritual principle of cause and effect where intent and actions of an individual influence their future. We chose this concept as the foundation of our name because it aligns perfectly with our mission.
      </p>
      <h2>The Significance</h2>
      <p>
        By using K4M2A instead of simply "Karma", we're doing a few things:
      </p>
      <ol>
        <li>We're creating a unique, memorable brand name that stands out.</li>
        <li>We're paying homage to the digital age, where alphanumeric codes are common.</li>
        <li>We're inviting curiosity and encouraging people to look deeper - just as we encourage our users to look deeper into themselves and their spiritual journey.</li>
      </ol>
      <h2>Our Vision</h2>
      <p>
        At K4M2A, we believe that every action, thought, and intention contributes to our spiritual growth and the collective consciousness of humanity. Our platform is designed to help individuals understand and harness this power, guiding them towards enlightenment and personal growth.
      </p>
      <p>
        By choosing this name, we're making a statement: we're here to bridge the gap between ancient wisdom and modern technology, creating a space where seekers can explore, learn, and grow together.
      </p>
      <h2>Conclusion</h2>
      <p>
        K4M2A is more than just a name - it's a philosophy, a mission, and an invitation. An invitation to explore the depths of your being, to understand the interconnectedness of all things, and to embark on a journey of spiritual growth and self-discovery.
      </p>
      <p>
        We hope that every time you see or hear "K4M2A", you're reminded of the powerful journey you're on and the community of seekers you're a part of.
      </p>
    `
  },
  // Add more blog posts here
};

export default function BlogPost() {
  const { slug } = useParams();
  const post = blogPosts[slug];

  if (!post) {
    return <div>Blog post not found</div>;
  }

  return (
    <article className="py-24 px-4">
      <div className="container max-w-[800px] mx-auto">
        <Link to="/blog" className="inline-flex items-center text-blue-600 hover:underline mb-8">
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to all posts
        </Link>
        <h1 className="text-[40px] sm:text-[50px] md:text-[60px] leading-[1.1] font-normal tracking-[-0.02em] mb-6">
          {post.title}
        </h1>
        <p className="text-lg text-[#333333] mb-4">Published on {post.date}</p>
        <div className="prose prose-lg max-w-none" dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>
    </article>
  );
}

