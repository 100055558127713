import React from 'react';
import { Button } from "../components/ui/button";

export default function Press() {
  return (
    <main className="min-h-screen bg-[#f7f4ed]">
      <section className="py-24 px-4">
        <div className="container max-w-[800px] mx-auto">
          <h1 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-6 text-center">
            Press Inquiries
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl text-[#333333] mb-6">
            We're always excited to share our story and vision with the media. If you're a journalist or blogger interested in covering K4M2A, we'd love to hear from you.
          </p>
          <p className="text-lg sm:text-xl md:text-2xl text-[#333333] mb-6">
            Please reach out to our press team for any inquiries, interview requests, or additional information about K4M2A.
          </p>
          <div className="text-center">
            <a href="mailto:press@k4m2a.com" className="inline-block px-8 py-4 bg-[#1a1a1a] hover:bg-black text-white font-normal text-lg rounded-full transition-colors duration-300">
              Contact Press Team
            </a>
          </div>
        </div>
      </section>
      <section className="py-24 px-4">
        <div className="container max-w-[800px] mx-auto text-center">
          <h2 className="text-[32px] sm:text-[48px] md:text-[64px] leading-[1.1] font-normal tracking-[-0.02em] mb-6">
            Media Resources
          </h2>
          <p className="text-lg sm:text-xl md:text-2xl text-[#333333] mb-8">
            Download our press kit for high-resolution logos, executive headshots, and product images.
          </p>
          <Button className="text-lg px-8 py-4 bg-[#1a1a1a] hover:bg-black text-white font-normal rounded-full">
            Download Press Kit
          </Button>
        </div>
      </section>
    </main>
  );
}

