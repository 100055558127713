import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Check } from 'lucide-react';

const blogPosts = [
  { title: "Why the name K4M2A?", date: "June 1, 2023", slug: "why-the-name-k4m2a", image: "/placeholder.svg" },
  { title: "How K4M2A will integrate science with spirituality", date: "May 15, 2023", slug: "integrating-science-and-spirituality", image: "/placeholder.svg" },
  { title: "The Future of Spiritual Growth", date: "May 1, 2023", slug: "future-of-spiritual-growth", image: "/placeholder.svg" },
];

export default function Blog() {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Newsletter form submitted with email:", email);
    setIsSubscribed(true);
    setEmail('');
  };

  return (
    <main className="min-h-screen bg-[#f7f4ed]">
      <section className="min-h-screen flex flex-col items-center justify-center py-20 px-4">
        <div className="container max-w-[1200px] mx-auto text-center">
          <h1 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-6">
            Blog
          </h1>
          <p className="text-xl sm:text-2xl md:text-[32px] mb-16 text-[#333333]">
            Exploring the intersection of spirituality, technology, and personal growth
          </p>
        </div>
      </section>
      <section className="py-24 px-4">
        <div className="container max-w-[800px] mx-auto">
          <div className="space-y-12">
            {blogPosts.map((post, index) => (
              <article key={index} className="border-b border-gray-200 pb-12">
                <img 
                  src={post.image} 
                  alt={post.title} 
                  className="w-full h-[400px] object-cover rounded-lg mb-6"
                />
                <h2 className="text-2xl sm:text-3xl font-semibold mb-2">
                  <Link to={`/blog/${post.slug}`} className="hover:underline">
                    {post.title}
                  </Link>
                </h2>
                <p className="text-[#333333] mb-4">{post.date}</p>
                <Link to={`/blog/${post.slug}`} className="text-blue-600 hover:underline">
                  Read more
                </Link>
              </article>
            ))}
          </div>
        </div>
      </section>
      <section className="py-24 px-4 bg-secondary">
        <div className="container max-w-[900px] mx-auto text-center">
          <h2 className="text-[32px] sm:text-[48px] md:text-[64px] leading-[1.1] font-normal tracking-[-0.02em] mb-12">Subscribe to Our Newsletter</h2>
          <p className="text-lg sm:text-xl md:text-2xl text-[#333333] mb-6">
            Stay updated with the latest insights, tips, and news from K4M2A.
          </p>
          {isSubscribed ? (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-6" role="alert">
              <div className="flex items-center justify-center">
                <Check className="w-6 h-6 mr-2" />
                <span className="block sm:inline text-lg">Thank you for subscribing! Check your email for confirmation.</span>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="relative max-w-[600px] mx-auto mb-12">
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full h-[60px] pl-6 pr-6 sm:pr-[180px] text-lg bg-white rounded-full border-[#E5E7EB] focus:ring-2 focus:ring-black shadow-sm"
              />
              <Button 
                type="submit"
                className="w-full sm:w-auto mt-4 sm:mt-0 sm:absolute sm:right-1 sm:top-1 h-[52px] px-8 bg-[#1a1a1a] hover:bg-black text-white font-normal text-lg rounded-full"
              >
                Subscribe
              </Button>
            </form>
          )}
        </div>
      </section>
    </main>
  );
}

