import React from 'react';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="py-16 px-4 bg-secondary text-primary border-t border-border">
      <div className="container max-w-[1200px] mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          <div className="col-span-1 sm:col-span-2 md:col-span-1">
            <h3 className="text-2xl font-semibold mb-4">K4M2A</h3>
            <p className="text-lg text-muted-foreground">Space for Seekers</p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Company</h4>
            <ul className="space-y-2">
              <li><Link to="/about" className="text-lg text-muted-foreground hover:text-primary transition-colors">About</Link></li>
              <li><Link to="/careers" className="text-lg text-muted-foreground hover:text-primary transition-colors">Careers</Link></li>
              <li><Link to="/faq" className="text-lg text-muted-foreground hover:text-primary transition-colors">FAQ</Link></li>
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Legal</h4>
            <ul className="space-y-2">
              <li><Link to="/privacy" className="text-lg text-muted-foreground hover:text-primary transition-colors">Privacy Policy</Link></li>
              <li><Link to="/terms" className="text-lg text-muted-foreground hover:text-primary transition-colors">Terms of Service</Link></li>
              <li><Link to="/press" className="text-lg text-muted-foreground hover:text-primary transition-colors">Press</Link></li>
            </ul>
          </div>
        </div>
        <div className="mt-16 pt-8 border-t border-border text-center text-lg text-muted-foreground">
          © {new Date().getFullYear()} K4M2A. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

