import React from 'react';

export function WhatIsK4M2A() {
  return (
    <section className="py-24 md:py-32 px-4 bg-secondary">
      <div className="container max-w-[1200px] mx-auto text-center">
        <h2 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-6">
          What is K4M2A?
        </h2>
        <p className="text-lg sm:text-xl md:text-2xl max-w-[800px] mx-auto text-[#333333] leading-relaxed">
          Building on the timeless wisdom of ancient spiritual practices and the power of modern technology, K4M2A is creating the next evolution of personalized spiritual growth.
        </p>
      </div>
    </section>
  );
}

