import React, { useState } from 'react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { useToast } from "./ui/use-toast";
import { captureEmail } from '../api/mockApi';

export function ReservationCounter() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e) => {
    debugger
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = await captureEmail(email);

      if (data.success) {
        toast({
          title: "Invite Requested",
          description: "Thank you for your interest. We'll be in touch soon!",
        });
        setEmail('');
      } else {
        throw new Error(data.message || 'Something went wrong');
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "There was a problem submitting your request. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="py-24 px-4 bg-background">
      <div className="container max-w-[900px] mx-auto text-center">
        <h2 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-12">Join our pre-launch.<br/>
          Receive your own K4M2A kit.</h2>
        <p className="text-lg sm:text-xl md:text-2xl text-[#333333] mb-6">
          Invites are in high demand and only a limited number are released each week. Sign up now to join the waiting list—be one of the first to experience K4M2A when invites become available!
        </p>

        <form onSubmit={handleSubmit} className="relative max-w-[600px] mx-auto mb-12">
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full h-[60px] pl-6 pr-6 sm:pr-[180px] text-lg bg-white rounded-full border-[#E5E7EB] focus:ring-2 focus:ring-black shadow-sm"
          />
          <Button 
            type="submit"
            className="w-full sm:w-auto mt-4 sm:mt-0 sm:absolute sm:right-1 sm:top-1 h-[52px] px-8 bg-[#1a1a1a] hover:bg-black text-white font-normal text-lg rounded-full"
            disabled={isLoading}
          >
            {isLoading ? 'Requesting...' : 'Request Invite'}
          </Button>
        </form>
      </div>
    </section>
  );
}

