import React from 'react';

export default function PrivacyPolicy() {
  return (
    <main className="min-h-screen bg-[#f7f4ed]">
      <section className="py-24 px-4">
        <div className="container max-w-[800px] mx-auto">
          <h1 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-6 text-center">
            Privacy Policy
          </h1>
          <div className="space-y-6 text-lg sm:text-xl text-[#333333]">
            <p>
              At K4M2A, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform.
            </p>
            <h2 className="text-2xl sm:text-3xl font-semibold mt-8 mb-4">Information We Collect</h2>
            <p>
              We collect information that you provide directly to us, such as when you create an account, use our services, or communicate with us. This may include your name, email address, and other personal information.
            </p>
            <h2 className="text-2xl sm:text-3xl font-semibold mt-8 mb-4">How We Use Your Information</h2>
            <p>
              We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to personalize your experience on K4M2A.
            </p>
            <h2 className="text-2xl sm:text-3xl font-semibold mt-8 mb-4">Data Security</h2>
            <p>
              We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.
            </p>
            <p>
              For the full Privacy Policy, please contact us at privacy@k4m2a.com.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

