import React, { useEffect } from 'react';
import { Header } from "../components/Header";
import { Hero } from "../components/Hero";
import { WhatIsK4M2A } from "../components/WhatIsK4M2A";
import { Story } from "../components/Story";
import { CTA } from "../components/CTA";
import { Footer } from "../components/Footer";
import { Testimonials } from "../components/Testimonials";
import { Roadmap } from "../components/Roadmap";
import { ReservationCounter } from "../components/ReservationCounter";
import Cookies from 'js-cookie';

export default function Home() {
  useEffect(() => {
    // Extract inviter from the URL path (e.g., https://k4m2a.com/NQ==)
    const path = window.location.pathname; // "/NQ=="
    const inviter = decodeURIComponent(path.substring(1)); // "NQ=="

    // Save inviter to cookies if not already set
    if (inviter && !Cookies.get('inviter')) {
      Cookies.set('inviter', inviter, { expires: 7 }); // Expires in 7 days
    }
  }, []);
  
  return (
    <main className="min-h-screen bg-[#f7f4ed]">
      <Header />
      <Hero />
      <WhatIsK4M2A />
      <Testimonials />
      <Story />
      <Roadmap />
      <CTA />
      <ReservationCounter />
    </main>
  );
}

