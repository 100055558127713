import React from 'react';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export function MobileMenu({ isOpen, onClose }: MobileMenuProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-white">
      <div className="flex justify-end p-4">
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <X className="h-6 w-6" />
        </button>
      </div>
      <nav className="flex flex-col items-center space-y-4 p-4">
        <Link to="/" className="text-xl font-medium" onClick={onClose}>Home</Link>
        <Link to="/about" className="text-xl font-medium" onClick={onClose}>About</Link>
        <Link to="/blog" className="text-xl font-medium" onClick={onClose}>Blog</Link>
        <Link to="/careers" className="text-xl font-medium" onClick={onClose}>Careers</Link>
        <Link to="/faq" className="text-xl font-medium" onClick={onClose}>FAQ</Link>
      </nav>
    </div>
  );
}

