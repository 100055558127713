import React from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

export function RoadmapItem({ phase, title, description, isExpanded, onToggle }) {
  return (
    <div className="border border-border rounded-lg transition-all duration-300 ease-in-out hover:shadow-md bg-card">
      <div 
        className="flex justify-between items-center p-6 cursor-pointer"
        onClick={onToggle}
      >
        <div>
          <h3 className="text-xl sm:text-2xl font-semibold text-primary">{title}</h3>
          <p className="text-lg text-muted-foreground mt-1">{phase}</p>
        </div>
        {isExpanded ? <ChevronUp className="w-6 h-6 text-primary" /> : <ChevronDown className="w-6 h-6 text-primary" />}
      </div>
      {isExpanded && (
        <p className="px-6 pb-6 text-lg text-muted-foreground">{description}</p>
      )}
    </div>
  );
}

