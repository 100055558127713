import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqItems = [
  {
    question: "What is K4M2A?",
    answer: "K4M2A is a revolutionary platform that combines ancient wisdom with modern technology to provide personalized spiritual growth experiences."
  },
  {
    question: "How does K4M2A work?",
    answer: "K4M2A uses advanced algorithms and AI to create tailored spiritual journeys based on your individual needs, preferences, and goals."
  },
  {
    question: "Is K4M2A affiliated with any specific religion?",
    answer: "No, K4M2A is not affiliated with any specific religion. We draw from various spiritual traditions and scientific research to create a universal approach to personal growth."
  },
  {
    question: "How can I join K4M2A?",
    answer: "Currently, K4M2A is in its pre-launch phase. You can join our waiting list by signing up on our homepage to be notified when we launch."
  },
];

export default function FAQ() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <main className="min-h-screen bg-[#f7f4ed]">
      <section className="py-24 px-4">
        <div className="container max-w-[800px] mx-auto">
          <h1 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-12 text-center">
            Frequently Asked Questions
          </h1>
          <div className="space-y-8">
            {faqItems.map((item, index) => (
              <div key={index} className="border-b border-gray-200 pb-8">
                <button
                  className="flex justify-between items-center w-full text-left"
                  onClick={() => toggleExpand(index)}
                >
                  <h2 className="text-2xl sm:text-3xl font-semibold">{item.question}</h2>
                  {expandedIndex === index ? (
                    <ChevronUp className="w-6 h-6" />
                  ) : (
                    <ChevronDown className="w-6 h-6" />
                  )}
                </button>
                {expandedIndex === index && (
                  <p className="text-lg sm:text-xl text-[#333333] mt-4">{item.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="py-24 px-4 bg-white">
        <div className="container max-w-[800px] mx-auto text-center">
          <h2 className="text-[32px] sm:text-[48px] md:text-[64px] leading-[1.1] font-normal tracking-[-0.02em] mb-6">
            Still Have Questions?
          </h2>
          <p className="text-lg sm:text-xl md:text-2xl text-[#333333] mb-8">
            Our support team is here to help. Reach out to us and we'll get back to you as soon as possible.
          </p>
          <a href="mailto:support@k4m2a.com" className="px-8 py-4 bg-[#1a1a1a] hover:bg-black text-white font-normal text-lg rounded-full transition-colors duration-300">
            Contact Support
          </a>
        </div>
      </section>
    </main>
  );
}

