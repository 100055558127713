import React, { useState } from 'react';
import { RoadmapItem } from './RoadmapItem';

const roadmapData = [
  {
    phase: "Phase 1 - Q1 2025",
    title: "Foundation of Connection",
    description: "Launch of core features like timelines, posts, events, and connections to foster authentic self-expression and meaningful interactions."
  },
  {
    phase: "Phase 2 - Q2 2025",
    title: "Community & Shared Wisdom",
    description: "Introduction of community-building tools, group creation, and \"Ask Me Anything\" for spiritual guides to encourage shared learning and connection."
  },
  {
    phase: "Phase 3 - Q3 2025",
    title: "Immersive Growth & Personalized Focus",
    description: "Launch of Dwell Mode to deeply explore specific subjects, Focus Mode to block distractions and engage only with desired content, and Meditation Mode to create peaceful, distraction-free mindfulness spaces."
  },
  {
    phase: "Phase 4 - Q4 2025",
    title: "Empowering Creators & Nurturing Young Minds",
    description: "Launch of professional tools for creators, artists, and healers to share their wisdom and inspire growth. Kids Mode will provide age-appropriate spiritual content, fostering mindfulness and emotional growth in children."
  },
  {
    phase: "Phase 5 - Q1 2026",
    title: "Retreat Centers",
    description: "A physical and digital space built on ancient architectural principles to elevate the spirit through hundreds of meditation techniques. This retreat centers will host spiritual practices and events, with cutting-edge technology enabling immersive online and offline experiences."
  },
  {
    phase: "Phase 6 - Q2 2026",
    title: "Enlightenment School",
    description: "A revolutionary school designed to prepare individuals for the journey of life, focusing on the exploration of self and the pursuit of enlightenment through tailored learning paths and holistic growth."
  },
  {
    phase: "Phase 7 - Q3 2026",
    title: "Open-Source R&D for Enlightenment",
    description: "Dedicated research into over 20 open-source enlightenment technologies, fostering innovation and collaboration to advance the global understanding and accessibility of spiritual growth tools."
  },
  {
    phase: "Phase 8 - Q4 2026",
    title: "Common Definition and Assessment for Enlightenment",
    description: "Development of a universally accepted framework to define and assess enlightenment. This phase will bring together scientists, spiritual leaders, and philosophers to create a shared language and measurable criteria for understanding and achieving enlightenment, enabling individuals and communities to track and celebrate progress on their spiritual journeys."
  },
  {
    phase: "Phase 9 - Q2 2027",
    title: "Enlightenment University",
    description: "Establishment of the Enlightenment University, a global institution dedicated to the study, practice, and advancement of spiritual growth and self-realization. Combining ancient wisdom with modern research, the university will offer immersive programs, interdisciplinary studies, and a collaborative environment for seekers, scholars, and innovators to explore the depths of consciousness and enlightenment."
  }
];

export function Roadmap() {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  return (
    <section className="py-24 px-4 bg-background">
      <div className="container max-w-[1200px] mx-auto">
        <h2 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-12 text-center">The Roadmap</h2>
        <div className="max-w-[800px] mx-auto">
          <div className="space-y-4">
            {roadmapData.map((item, index) => (
              <RoadmapItem
                key={index}
                {...item}
                isExpanded={expandedIndex === index}
                onToggle={() => toggleExpand(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

