const Cookies = require('js-cookie');

export const captureEmail = async (email: string) => {
  try {
    // Validate email input
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return {
        success: false,
        message: 'Invalid email address',
      };
    }

    // Retrieve inviter from cookies
    const inviter = Cookies.get('inviter') || '';

    const baseUrl = process.env.REACT_APP_API_URL;
    if (!baseUrl) throw new Error('API base URL is not defined in .env file');

    const response = await fetch(`${baseUrl}/user/requestinvite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, inviter }),
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = await response.json();
    return {
      success: true,
      data,
    };
  } catch (error) {
    if (error instanceof TypeError) {
      return {
        success: false,
        message: 'Network error: Please check your internet connection.',
      };
    }

    if (error instanceof Error) {
      return {
        success: false,
        message: error.message,
      };
    }

    return {
      success: false,
      message: 'An unexpected error occurred',
    };
  }
};
