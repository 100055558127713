import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "./ui/button";
import { Menu, Share2, Check } from 'lucide-react';
import { MobileMenu } from './MobileMenu';

export function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [buttonText, setButtonText] = useState({ desktop: "Share with a Seeker", mobile: "Share" });
  const [isScrolled, setIsScrolled] = useState(false);

  const handleButtonClick = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setButtonText({ desktop: "URL Copied!", mobile: "Copied!" });
      setTimeout(() => setButtonText({ desktop: "Share with a Seeker", mobile: "Share" }), 2000);
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 px-4 py-4 transition-all duration-300 ${
      isScrolled ? 'bg-white/80 backdrop-blur-md border-b border-[#E5E7EB]' : 'bg-transparent'
    }`}>
      <div className="container mx-auto flex items-center justify-between">
        <Button 
          variant="ghost" 
          size="icon" 
          onClick={() => setIsMobileMenuOpen(true)} 
          className="hover:bg-secondary rounded-full p-2 touch-manipulation"
        >
          <Menu className="h-8 w-8" />
        </Button>
        <div className="absolute left-1/2 transform -translate-x-1/2">
          <Link to="/" className="text-2xl font-bold">
            K4M2A
          </Link>
        </div>
        <div 
          className={`sm:hidden flex items-center gap-2 cursor-pointer`}
          onClick={handleButtonClick}
        >
          {buttonText.mobile === "Copied!" ? <Check className="w-5 h-5" /> : <Share2 className="w-5 h-5" />}
          <span>{buttonText.mobile}</span>
        </div>
        <Button 
          variant="outline"
          className="hidden sm:flex items-center gap-2 border-[#E5E7EB]"
          onClick={handleButtonClick}
        >
          {buttonText.desktop === "URL Copied!" ? <Check className="w-5 h-5" /> : <Share2 className="w-5 h-5" />}
          <span>{buttonText.desktop}</span>
        </Button>
      </div>
      <MobileMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
    </header>
  );
}

