import React, { useState } from 'react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { useToast } from "./ui/use-toast";
import { captureEmail } from '../api/mockApi';

export function Hero() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = await captureEmail(email);

      if (data.success) {
        toast({
          title: "Invite Requested",
          description: "Thank you for your interest. We'll be in touch soon!",
        });
        setEmail('');
      } else {
        throw new Error(data.message || 'Something went wrong');
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "There was a problem submitting your request. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="min-h-screen flex flex-col items-center justify-center py-12 sm:py-20 px-4">
      <div className="container max-w-[1200px] mx-auto text-center">
        <h1 className="text-3xl sm:text-[40px] md:text-[60px] lg:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-4 sm:mb-6 text-[#333333]">
          A new way to<br />
          <span className="font-normal">"enlighten"</span>
        </h1>
        <p className="text-lg sm:text-xl md:text-2xl lg:text-[32px] mb-8 sm:mb-16 text-[#333333]">
          Welcome to the era of personalised spiritual growth
        </p>
        <form onSubmit={handleSubmit} className="relative max-w-[600px] mx-auto">
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full h-[50px] sm:h-[60px] pl-4 sm:pl-6 pr-4 sm:pr-6 text-base sm:text-lg bg-white rounded-full border-[#E5E7EB] focus:ring-2 focus:ring-black shadow-sm"
            required
          />
          <Button 
            type="submit" 
            className="w-full sm:w-auto mt-4 sm:mt-0 sm:absolute sm:right-1 sm:top-1 h-[46px] sm:h-[52px] px-6 sm:px-8 bg-[#1a1a1a] hover:bg-black text-white font-normal text-base sm:text-lg rounded-full"
            disabled={isLoading}
          >
            {isLoading ? 'Requesting...' : 'Request Invite'}
          </Button>
        </form>
      </div>
    </section>
  );
}

