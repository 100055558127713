import React from 'react';

export default function TermsOfService() {
  return (
    <main className="min-h-screen bg-[#f7f4ed]">
      <section className="py-24 px-4">
        <div className="container max-w-[800px] mx-auto">
          <h1 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-6 text-center">
            Terms of Service
          </h1>
          <div className="space-y-6 text-lg sm:text-xl text-[#333333]">
            <p>
              Welcome to K4M2A. By using our platform, you agree to these Terms of Service. Please read them carefully.
            </p>
            <h2 className="text-2xl sm:text-3xl font-semibold mt-8 mb-4">Use of Our Services</h2>
            <p>
              You must follow any policies made available to you within the Services. Don't misuse our Services. For example, don't interfere with our Services or try to access them using a method other than the interface and the instructions that we provide.
            </p>
            <h2 className="text-2xl sm:text-3xl font-semibold mt-8 mb-4">Your K4M2A Account</h2>
            <p>
              You may need a K4M2A Account in order to use some of our Services. You may create your own K4M2A Account, or your K4M2A Account may be assigned to you by an administrator, such as your employer or educational institution.
            </p>
            <h2 className="text-2xl sm:text-3xl font-semibold mt-8 mb-4">Privacy and Copyright Protection</h2>
            <p>
              K4M2A's privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that K4M2A can use such data in accordance with our privacy policies.
            </p>
            <p>
              For the full Terms of Service, please contact us at legal@k4m2a.com.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

