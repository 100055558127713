import React from 'react';
import { Link } from 'react-router-dom';

const openPositions = [
  { title: "Senior Full Stack Developer", department: "Engineering", location: "Remote" },
  { title: "UX/UI Designer", department: "Design", location: "San Francisco, CA" },
  { title: "Spiritual Content Creator", department: "Content", location: "Remote" },
  { title: "Marketing Manager", department: "Marketing", location: "New York, NY" },
];

export default function Careers() {
  return (
    <main className="min-h-screen bg-[#f7f4ed]">
      <section className="min-h-screen flex flex-col items-center justify-center py-20 px-4">
        <div className="container max-w-[1200px] mx-auto text-center">
          <h1 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-6">
            Careers
          </h1>
          <p className="text-xl sm:text-2xl md:text-[32px] mb-16 text-[#333333]">
            Join us in our mission to revolutionize spiritual growth and personal development
          </p>
        </div>
      </section>
      <section className="py-24 px-4">
        <div className="container max-w-[800px] mx-auto">
          <div className="space-y-8">
            {openPositions.map((position, index) => (
              <div key={index} className="border-b border-gray-200 pb-8">
                <h2 className="text-2xl sm:text-3xl font-semibold mb-2">{position.title}</h2>
                <p className="text-[#333333] mb-4">{position.department} | {position.location}</p>
                <Link to="mailto:careers@k4m2a.com" className="text-blue-600 hover:underline">
                  Apply
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="py-24 px-4 bg-white">
        <div className="container max-w-[1200px] mx-auto">
          <h2 className="text-[32px] sm:text-[48px] md:text-[64px] leading-[1.1] font-normal tracking-[-0.02em] mb-12 text-center">
            Life at K4M2A
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { title: "Innovation", description: "Work on cutting-edge technology that bridges spirituality and AI" },
              { title: "Growth", description: "Continuous learning and personal development opportunities" },
              { title: "Impact", description: "Make a real difference in people's lives and spiritual journeys" }
            ].map((benefit, index) => (
              <div key={index} className="text-center">
                <h3 className="text-2xl font-semibold mb-4">{benefit.title}</h3>
                <p className="text-lg text-[#333333]">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="py-24 px-4">
        <div className="container max-w-[800px] mx-auto text-center">
          <h2 className="text-[32px] sm:text-[48px] md:text-[64px] leading-[1.1] font-normal tracking-[-0.02em] mb-6">
            Our Values
          </h2>
          <p className="text-lg sm:text-xl md:text-2xl text-[#333333] mb-8">
            At K4M2A, we're driven by a set of core values that shape our culture and guide our work.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {[
              { value: "Authenticity", description: "We encourage genuine self-expression and honesty in all interactions." },
              { value: "Curiosity", description: "We foster a culture of continuous learning and exploration." },
              { value: "Empathy", description: "We prioritize understanding and compassion in our work and relationships." },
              { value: "Innovation", description: "We push boundaries and embrace creative solutions to complex challenges." }
            ].map((item, index) => (
              <div key={index} className="text-left">
                <h3 className="text-xl font-semibold mb-2">{item.value}</h3>
                <p className="text-[#333333]">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
}

