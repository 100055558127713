import React from 'react';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Check } from 'lucide-react';

export default function About() {
  const [email, setEmail] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log('Email submitted:', email);
  };

  const mainProjects = [
    "A spiritual social media platform for connection and growth",
    "A school dedicated to preparing individuals for enlightenment",
    "A research lab for open-source enlightenment innovations",
    "A retreat centers designed for meditation and spiritual practices",
    "A global directory of spiritual places and wisdom resources",
    "Spiritual content curated specifically for young minds"
  ];

  return (
    <main className="min-h-screen bg-[#f7f4ed]">
      <section className="min-h-screen flex flex-col items-center justify-center py-20 px-4">
        <div className="container max-w-[1200px] mx-auto text-center">
          <h1 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-6">
            About
          </h1>
          <p className="text-xl sm:text-2xl md:text-[32px] mb-16 text-[#333333]">
            Revolutionizing spiritual growth through technology and ancient wisdom
          </p>
        </div>
      </section>

      <section className="py-24 px-4 bg-secondary">
        <div className="container max-w-[1200px] mx-auto text-center">
          <h2 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-12">Our Main Projects</h2>
          <div className="grid md:grid-cols-2 gap-8 max-w-[800px] mx-auto text-left mb-12">
            {mainProjects.map((project, index) => (
              <div key={index} className="flex items-start">
                <Check className="w-6 h-6 text-primary mr-3 flex-shrink-0 mt-1" />
                <p className="text-base sm:text-lg md:text-xl text-[#333333]">{project}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-24 px-4">
        <div className="container max-w-[900px] mx-auto text-center">
          <h2 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-12">Join us on this Journey</h2>
          <p className="text-lg sm:text-xl md:text-2xl text-[#333333] mb-6">
            Be part of the K4M2A community and embark on a transformative experience towards enlightenment.
          </p>
          <form onSubmit={handleSubmit} className="relative max-w-[600px] mx-auto mb-12">
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-[60px] pl-6 pr-6 sm:pr-[180px] text-lg bg-white rounded-full border-[#E5E7EB] focus:ring-2 focus:ring-black shadow-sm"
              required
            />
            <Button 
              type="submit"
              className="w-full sm:w-auto mt-4 sm:mt-0 sm:absolute sm:right-1 sm:top-1 h-[52px] px-8 bg-[#1a1a1a] hover:bg-black text-white font-normal text-lg rounded-full"
            >
              Request Invite
            </Button>
          </form>
        </div>
      </section>
    </main>
  );
}

