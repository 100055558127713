import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const testimonials = [
  {
    quote: "After seeing a demo of K4M2A, I'm blown away by its potential. The personalized growth tools and immersive experiences look like they'll revolutionize my spiritual journey. I can't wait to connect with seekers worldwide when it launches!",
    name: "Sarah L.",
    role: "Meditation Practitioner",
    rating: 5
  },
  {
    quote: "As a tech entrepreneur, I'm impressed by how K4M2A plans to blend cutting-edge technology with ancient wisdom. The preview of the AI-powered personal guide and VR meditation spaces has me counting down to the full release!",
    name: "Michael R.",
    role: "Tech Entrepreneur",
    rating: 5
  },
  {
    quote: "The sneak peek of K4M2A's features for yoga instructors has me thrilled about the possibilities. The innovative tools for creating immersive classes look game-changing. I'm eager to grow alongside my students when it's fully available.",
    name: "Elena T.",
    role: "Yoga Instructor",
    rating: 5
  }
];

export function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  return (
    <section className="py-12 sm:py-24 px-4 bg-secondary">
      <div className="container max-w-[1200px] mx-auto text-center">
        <h2 className="text-3xl sm:text-[40px] md:text-[60px] lg:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-8 sm:mb-12">Early Impressions</h2>
        <div className="relative max-w-[800px] mx-auto">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="space-y-6"
            >
              <img
                src={`/testimonial-${currentIndex + 1}.jpg`}
                alt={`${testimonials[currentIndex].name}'s portrait`}
                className="w-24 h-24 rounded-full mx-auto mb-4"
              />
              <p className="text-base sm:text-lg md:text-xl lg:text-2xl italic text-[#333333]">
                "{testimonials[currentIndex].quote}"
              </p>
              <div className="flex flex-col items-center">
                <div className="font-semibold text-lg sm:text-xl text-primary">{testimonials[currentIndex].name}</div>
                <div className="text-base sm:text-lg text-muted-foreground">{testimonials[currentIndex].role}</div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
}

