import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Label } from "../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";

export default function Qualify() {
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [shareExpertise, setShareExpertise] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log('Form submitted');
    setIsSubmitted(true);
  };

  return (
    <main className="min-h-screen bg-[#f7f4ed]">
      <section className="py-24 px-4 bg-secondary">
        <div className="container max-w-[800px] mx-auto text-center">
          <h1 className="text-[40px] sm:text-[50px] md:text-[60px] leading-[1.1] font-normal tracking-[-0.02em] mb-6">
            Thank You for Requesting an Invite!
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl text-[#333333] mb-8">
            We're thrilled by your interest.
          </p>
        </div>
      </section>

      <section className="py-12 px-4">
        <div className="container max-w-[800px] mx-auto">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 text-center">Invite Request Form</h2>
            <p className="text-lg text-[#333333] mb-8 text-center">
              Because we receive a lot of requests every day, to help us process your application faster and improve your chances of being accepted, please take a moment to answer the questions below. Your responses will help us better understand how K4M2A aligns with your journey.
            </p>
            <form onSubmit={handleSubmit} className="space-y-8">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input type="email" id="email" value={email} readOnly className="w-full bg-gray-100" />
              </div>
              <div className="space-y-2">
                <Label htmlFor="fullName">What is your full name? (Required)</Label>
                <Input type="text" id="fullName" required className="w-full" />
              </div>
              <div className="space-y-2">
                <Label htmlFor="country">Where are you from?</Label>
                <Select name="country">
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select your country" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="us">United States</SelectItem>
                    <SelectItem value="ca">Canada</SelectItem>
                    <SelectItem value="uk">United Kingdom</SelectItem>
                    <SelectItem value="au">Australia</SelectItem>
                    {/* Add more countries as needed */}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="inspiration">What inspires you to join K4M2A?</Label>
                <Textarea id="inspiration" className="w-full" />
              </div>
              <div className="space-y-2">
                <Label htmlFor="currentPractice">How do you currently practice spirituality or mindfulness in your life?</Label>
                <Select name="currentPractice">
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select an option" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="meditation">Meditation</SelectItem>
                    <SelectItem value="yoga">Yoga</SelectItem>
                    <SelectItem value="texts">Spiritual texts</SelectItem>
                    <SelectItem value="none">None yet but interested</SelectItem>
                    <SelectItem value="other">Other</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="goals">What do you hope to achieve by joining K4M2A?</Label>
                <Textarea id="goals" className="w-full" />
              </div>
              <div className="space-y-2">
                <Label htmlFor="contribution">Are you interested in contributing to the K4M2A community? If yes, how?</Label>
                <Select name="contribution">
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select an option" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="insights">Sharing insights</SelectItem>
                    <SelectItem value="events">Hosting events</SelectItem>
                    <SelectItem value="content">Creating content</SelectItem>
                    <SelectItem value="participating">Simply participating</SelectItem>
                    <SelectItem value="other">Other</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label>Do you prefer online experiences, offline events, or a mix of both?</Label>
                <RadioGroup>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="online" id="online" />
                    <Label htmlFor="online">Online</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="offline" id="offline" />
                    <Label htmlFor="offline">Offline</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="mix" id="mix" />
                    <Label htmlFor="mix">Mix of both</Label>
                  </div>
                </RadioGroup>
              </div>
              <div className="space-y-2">
                <Label>Would you like to share your professional expertise to help advance K4M2A's mission?</Label>
                <RadioGroup onValueChange={setShareExpertise}>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="yes" id="yes" />
                    <Label htmlFor="yes">Yes</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="no" id="no" />
                    <Label htmlFor="no">No</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="unsure" id="unsure" />
                    <Label htmlFor="unsure">Unsure</Label>
                  </div>
                </RadioGroup>
                {shareExpertise === 'yes' && (
                  <div className="mt-2">
                    <Label htmlFor="profession">What is your profession?</Label>
                    <Input type="text" id="profession" className="w-full mt-1" />
                  </div>
                )}
              </div>
              <Button type="submit" className="w-full px-8 py-4 bg-[#1a1a1a] hover:bg-black text-white font-normal text-lg rounded-full">
                Submit
              </Button>
              {isSubmitted && (
                <div className="mt-4 text-center text-green-600 font-semibold">
                  Your Invite Request has been successfully submitted. You will receive an email when your request is accepted.
                </div>
              )}
            </form>
          </div>
        </div>
      </section>
    </main>
  );
}

