import React from 'react';

export function Story() {
  return (
    <section className="py-24 px-4 bg-background">
      <div className="container max-w-[1200px] mx-auto text-center">
        <h2 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-6">Our Mission</h2>
        <p className="text-lg sm:text-xl md:text-2xl text-[#333333] leading-relaxed max-w-[800px] mx-auto">
          K4M2A is defining what it means to be enlightened, together with spiritual gurus, scientists, and philosophers. With groundbreaking research, innovation, and technologies, and a community united by a shared common goal, we're paving the way for collective awakening.
        </p>
      </div>
    </section>
  );
}

