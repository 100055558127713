import React, { useState } from 'react';
import { Check } from 'lucide-react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { useToast } from "./ui/use-toast";
import { captureEmail } from '../api/mockApi';

const benefits = [
  "Personalized spiritual growth tools designed for your unique journey.",
  "Exclusive access to cutting-edge technologies that harmonize mind, body, and spirit.",
  "Invitations to community events fostering shared wisdom and enlightenment.",
  "Early access to immersive modes for deep learning and mindfulness.",
  "Best content on every spiritual topics from ancient to modern.",
  "Join a global network of like-minded spiritual seekers and content creators."
];

export function CTA() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = await captureEmail(email);

      if (data.success) {
        toast({
          title: "Invite Requested",
          description: "Thank you for your interest. We'll be in touch soon!",
        });
        setEmail('');
      } else {
        throw new Error(data.message || 'Something went wrong');
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "There was a problem submitting your request. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="py-24 px-4 bg-secondary">
      <div className="container max-w-[1200px] mx-auto text-center">
        <h2 className="text-[40px] sm:text-[60px] md:text-[80px] leading-[1.1] font-normal tracking-[-0.02em] mb-12">Get Early Access</h2>
        <div className="grid md:grid-cols-2 gap-8 max-w-[800px] mx-auto text-left mb-12">
          {benefits.map((benefit, index) => (
            <div key={index} className="flex items-start">
              <Check className="w-6 h-6 text-primary mr-3 flex-shrink-0 mt-1" />
              <p className="text-base sm:text-lg md:text-xl text-[#333333]">{benefit}</p>
            </div>
          ))}
        </div>
        <form onSubmit={handleSubmit} className="relative max-w-[600px] mx-auto">
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full h-[60px] pl-6 pr-6 sm:pr-[180px] text-lg bg-white rounded-full border-[#E5E7EB] focus:ring-2 focus:ring-black shadow-sm"
            required
          />
          <Button 
            type="submit" 
            className="w-full sm:w-auto mt-4 sm:mt-0 sm:absolute sm:right-1 sm:top-1 h-[52px] px-8 bg-[#1a1a1a] hover:bg-black text-white font-normal text-lg rounded-full"
            disabled={isLoading}
          >
            {isLoading ? 'Requesting...' : 'Request Invite'}
          </Button>
        </form>
      </div>
    </section>
  );
}

